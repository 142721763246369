import React from "react"
import { StaticQuery, graphql , Link } from "gatsby"
import Img from 'gatsby-image'
import SecHeader from "../components/secondHeader"
import SecNav from "../components/secNav"
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/webLogo.png"
import fli from '../images/FLI@2x.png'
import foodgath from '../images/FG@2x.png'
import washtenaw_lit from '../images/Washtenawliteracy@2x.png'
import spg from '../images/spg@2x.png'
import newsbanner from '../images/news_banner@2x.png'

const Programs = () => (



<StaticQuery
    query={graphql`
       query programsQuery{
        allWordpressWpPrograms{
    edges {
      node {
        title
        content
        acf{
          contact_info
        }
        featured_media {
             localFile{
                            childImageSharp{
                                resolutions(width:900, height: 500){
                                    src
                                    width
                                    height
                                }
                            }
                        }
            } 
        
      }
        
    }
  }
    }


    
    `}

  render={data => (

    <Layout>
    <SecHeader pageTitle="Programs" image={newsbanner} />
    <SEO title="Programs" />

<div class="uk-padding uk-section textme">

        

        <div class=" uk-child-width-1-2@m uk-flex uk-visible@m" uk-grid>
            <div class="uk-width-medium@m uk-margin-right">
                <h3>Programs</h3>
                <ul class=" secNav uk-text-large uk-nav uk-nav-default " uk-switcher="connect: #component-nav; animation: uk-animation-fade ">
                    <li><a href="#">Enrichment Program</a></li>
                    
                    <li><a href="#">Food Pantry</a></li>
                    <li><a href="#">Summer Playground Program</a></li>
                    <li><a href="#">Washtenaw Literacy</a></li>
                 </ul>

            </div>
         <div class="uk-width-expand@m">
        <ul id="component-nav" class="uk-switcher uk-text-small">
          {data.allWordpressWpPrograms.edges.map(({node}) => (
            <li>
              <div key={node.slug} class="uk-flex">
              <div class="uk-width-1-1 uk-padding-small">
                <h1>{node.title}</h1>
                <p class="uk-text-small " style={{color: '#2D4B65' }} dangerouslySetInnerHTML={{__html: node.content}}/>


              </div>
              <div class="uk-width-1-2 uk-margin-top">
            
              <img class="uk-width-large uk-margin-bottom" src={node.featured_media.localFile.childImageSharp.resolutions.src} alt=""/>
                <div class="programCon uk-padding-small uk-border-rounded">
                <h3 style={{color: "#E5901A"}}>Contact Info:</h3>
                <p> <b> {node.acf.contact_info} </b> </p>
          </div>
              </div>
              </div>


            </li>

          ))}




        </ul>
        

    </div>
            
        </div>

    </div>
    
   
  </Layout>
  )}

  />
)

export default Programs