/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import {Helmet} from "react-helmet";

import Header from "./header"
import "./layout.css"
import './layout.scss';


const TemplateWrapper = ({
  children,

}) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        allWordpressWpApiMenusMenusItems{
            edges{
                node{
                    id
                    name
                    items{
                        title
                        url
                        object_slug
                    }
                }
            }
        }
  }


    
    `}
    render={data => (
      <Layout
        data={data}
        children={children}

      />
    )}
  />
)

TemplateWrapper.propTypes = {
  children: PropTypes.func,
}





class Layout extends React.Component{
  
  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      this.UIkit = require('uikit/dist/js/uikit')
      this.Icons = require('uikit/dist/js/uikit-icons')
      this.UIkit.use(this.Icons)
      this.setState({ ready: true });
       this.Slideshow = require('react-slidez');
    }
  };
  render(){

console.log(this.props)
    return(

 <>
        <Header menu={this.props.data.allWordpressWpApiMenusMenusItems.edges[0].node.items}/>
         {/*<div class="uk-position-relative uk-visible-toggle uk-light" uk-slider>
    <ul class="uk-slider-items uk-child-width-1-2@s uk-child-width-1-5@m uk-grid">
        <li><img src="https://getuikit.com/images/favicon.png" alt=""/></li>
        <li><img src="https://getuikit.com/images/favicon.png" alt=""/></li>
        <li><img src="https://getuikit.com/images/favicon.png" alt=""/></li>
        <li><img src="https://getuikit.com/images/favicon.png" alt=""/></li>
        <li><img src="https://getuikit.com/images/favicon.png" alt=""/></li>
        <li><img src="https://getuikit.com/images/favicon.png" alt=""/></li>
        <li><img src="https://getuikit.com/images/favicon.png" alt=""/></li>
        <li><img src="https://getuikit.com/images/favicon.png" alt=""/></li>
    </ul>
    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
</div>*/}


<div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slideshow>

    <ul class="uk-slideshow-items">
        <li>
            <img src="https://getuikit.com/images/favicon.png" alt="" uk-cover/>
        </li>
        <li>
            <img src="https://getuikit.com/images/favicon.png" alt="" uk-cover/>
        </li>
        <li>
            <img src="https://getuikit.com/images/favicon.png" alt="" uk-cover/>
        </li>
    </ul>

    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>

</div>
        <div
          style={{
            margin: `0 auto`,
            //maxWidth: 960,
           // padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
          class="uk-flex-center" uk-grid
        >
          <main>{this.props.children}</main>
        
        </div>
         <footer class="uk-padding-small" >
           {/* © {new Date().getFullYear()}, Built with
            {` `}
              */}

            <div class="uk-flex uk-child-width-expand@s flexdirection" uk-grid>
    <div class="uk-container">Community Family Life Center <br/>
1375 S Harris Rd,<br/>
Ypsilanti, MI 48198<br/><br/>
cflcypsi@gmail.com<br/>
734-961-7058
</div>
    <div>Community Family Life Center is a 
501(c)(3) non-profit organization.
All donations to Community Family
Life Center are deductible.<br/><br/>
Connect With Us
</div>
    <div>Subscribe to Stay Updated <form class="uk-padding-small">
          <fieldset class="uk-fieldset " style={{width:"80%"}}>
            <input class="uk-input homeForm  uk-margin-small-bottom uk-border-rounded" type="text" placeholder="Name"/>
            <input class="uk-input  uk-margin-small-bottom uk-border-rounded" type="text" placeholder="Email Address"/>
            <button class="uk-button uk-button-secondary uk-border-rounded"> Subscribe</button> 

            </fieldset></form></div>
</div>
          </footer>
      </>
      )

  }

}

Layout.propTypes = {
  data: PropTypes.object.isRequired,
}


export default TemplateWrapper


