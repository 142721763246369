import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/webLogo.png"
import MobileLogo from "../images/MobileLogo@2x.png"
import "./layout.css"
import './layout.scss';

//import {Accordion} from 'react-uikit3';
// you can stub it to whatever you need to to make it run in node.
{/*let uiExt = {}
let Icon = {}
if (typeof document !== 'undefined') {

  uiExt = require('react-uikit3')
  Icon = uiExt.Icon


}
*/}


class Header extends React.Component{
  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      const uikit = require('uikit');
      const uiExt = require('react-uikit3')
      const icons = require('uikit/dist/js/uikit-icons.min');
      uikit.use(icons);
      let Accordian = uiExt.Accordion;
      this.setState({ ready: true });
    }
  };

  render(){
console.log(this.props)

    return (
 <React.Fragment>
  <div uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; bottom: #transparent-sticky-navbar" class=" navBottom uk-visible@m"  >
    <nav class="uk-navbar-container" uk-navbar ><div class="uk-navbar-left  uk-margin-right uk-margin-left">
    <a href="/" class="uk-visible@m"><img class="uk-width-small " src={Logo} alt=""/></a>
     
        <div class="uk-navbar-right">
        
        <ul class="mainNav uk-navbar-nav ">
                    {this.props.menu.map((item) =>
                        <li key={item.object_slug}>
                            <Link to={item.url}>
                                {item.title}
                            </Link>
                        </li>
                    )}
                    <li class='uk-margin-top'> 
                      <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
<input type="hidden" name="cmd" value="_s-xclick" />
<input type="hidden" name="hosted_button_id" value="CPJM87QS45MXS" />
<button class="uk-button uk-button-secondary" href="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gifr" name="submit">Donate</button>
<img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
</form>
</li>
                </ul>
        
        </div>

    </div></nav>
</div>


  <div uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; bottom: #transparent-sticky-navbar" class=" navBottom uk-hidden@m" style={{backgroundColor:'white'}}>
    


   {/* <Accordion>
    <li>
      <h3 className='uk-accordion-title'>
         <span uk-icon="icon: menu"></span> 
      </h3>
      <div className='uk-accordion-content' class="mobileNav">
        
      </div>
    </li>
    
  </Accordion>*/}
  <ul data-uk-accordion>
    
    <li  >

      <h3 className='uk-accordion-title'>
      <span uk-icon="icon: menu"></span>
      <img  src={MobileLogo}  height="15px" alt="mobile logo" style={{marginTop: '-25px', display: 'block' , marginLeft: 'auto', marginRight: 'auto'}}/>
       
      </h3>
      <div className='uk-accordion-content'>
        <ul class="uk-list ">
                    {this.props.menu.map((item) =>
                        <li key={item.object_slug}>
                            <Link to={item.url}>
                                {item.title}
                            </Link>
                        </li>
                    )}
                    <li  class="uk-text-primary">    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
<input type="hidden" name="cmd" value="_s-xclick" />
<input type="hidden" name="hosted_button_id" value="CPJM87QS45MXS" />
<button class="uk-button uk-button-secondary" href="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gifr" name="submit">Donate</button>
<img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
</form></li>
                </ul>
      </div>
    </li>
    
  </ul>

    {/*<nav class="uk-navbar uk-navbar-container uk-margin">
    <div class="uk-navbar-left">
        <a class="uk-navbar-toggle" href="#">
            <span uk-icon="icon: menu"></span> <span class="uk-margin-small-left">Menu</span>
        </a>
    </div>
</nav>*/}
</div>

</React.Fragment>

)
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  menu: PropTypes.func
  
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header



